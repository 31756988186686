.defaultStyle {
  font-family: 'Inter', sans-serif;
  background: #ffffff;
  border: 1px solid #d3dee8;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 4px;
  width: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  word-break: break-word;

  :global {
    .rdw-editor-wrapper {
      border: none;
    }
  }
}
