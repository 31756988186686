@import '../../../../assets/scss/variables';

.printWrapContent {
  padding: 0 !important;
  background-color: #f4f5f8;
  border-radius: 6px;
  font-family: $inter;
}

.wrapper {
  padding: 30px;

  .list {
    display: flex;
    flex-wrap: wrap;
    .item {
      font-size: 14px;
      color: $teal;
      padding: 7px 16px;
      background: rgba(0, 161, 156, 0.05);
      border-radius: 16px;
      margin-right: 10px;
      margin-top: 5px;
      text-transform: capitalize;
      background-color: rgba(0, 161, 156, 0.05);
    }
  }

  &:first-child {
    margin-bottom: -25px;
  }

  .title{
    color: #787587;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 16px;
  }

  .tableContainer {
    background: #ffffff;
    border-radius: 6px;
    table {
      width: 100%;
    }
    th,
    td {
      &:first-child {
        padding-left: 26px;
        padding-right: 35px;
      }
    }
    th {
      padding: 17px 0 13px 0;
      border-bottom: 2px solid #d3dee8;
      h3 {
        color: #3f3c4c;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
    tr {
      &:not(:last-child) {
        td {
          box-shadow: inset 0px -1px 0px #ececec;
        }
      }
    }
    td {
      vertical-align: top;
      padding: 11px 0 16px 0;
      background: rgba(0, 0, 0, 0.0001);
      p {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        word-break: break-word;
      }
    }

    .content {
      p {
        color: #3f3c4c;
        font-size: 14px;
        &:first-child {
          font-weight: 400;
        }
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }

    .source {
      text-align: right;
      font-size: 12px;
      font-style: italic;
      font-weight: 600;
      line-height: normal;
      white-space: nowrap;
      margin: 0;
    }
  }
}
