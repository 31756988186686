@import '../../assets/scss/variables';
.title {
  font-weight: 600;
  font-size: 20px;
  color: #5e6c84;
  margin-top: 28px;
}

.text2 {
  color: #3d3d3d;
  display: inline-block;
}

.text1,
.textHtml,
.textHtml * {
  font-family: $inter !important;
  font-weight: 500 !important;
  color: #3d3d3d !important;
}

@media print {
  .textHtml {
    display: inline-block;
    max-width: 500px;
  }
}

.customTable {
  vertical-align: top;
  p {
    margin-bottom: 5px !important;
  }
}
