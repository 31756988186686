@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

$white: #fff;
$gray: #e5e5e5;
$colorText: #344563;
$grayColor: #5e6c84;
$colorBorderDropdown: #dbdfe2;
$dropdownLabel: #dbdfe2;
$placeHolder: #9ea0a5;

.indicator {
  margin-top: 2px;
}

.indicatorPopover {
  div {
    color: white !important;
    white-space: nowrap;
  }

  .viewEditingHistorical {
    font-weight: 500 !important;
    margin-top: 4px;
    text-decoration: underline;
    cursor: pointer;
  }
}

.tableText {
  color: #3f3c4c;
}

.experience {
  background: #ffffff;
  border-radius: 6px;
  padding: 28px 26px 21px 24px;
  &__table {
    font-family: $inter;
    background: #ffffff;
    border: 1px solid #ececec;
    border-radius: 12px;
    width: 100%;
    border-collapse: separate !important;
    border-spacing: 0;
    overflow: hidden;

    th {
      @include showText(13px, 700, none, 15.73px);
      &:first-child {
        width: 20%;
      }
      &:nth-child(2) {
        width: 15%;
      }
      &:nth-child(3) {
        width: 10%;
      }
      &:nth-child(4) {
        width: 10%;
      }
      &:nth-child(5) {
        width: 10%;
      }
      &:nth-child(6) {
        width: 15%;
      }
      &:nth-child(7) {
        width: 10%;
      }
      &:nth-child(8) {
        width: 10%;
        border-right: none;
      }
    }

    td {
      @include showText(12px, 400, none, 15.73px);
    }

    th,
    td {
      border: 0.1px solid #ececec;
      text-align: center;
      color: #3f3c4c;
      padding: 15px 10px;
    }
  }

  &__h3 {
    @include showText(24px, 600, none, 29.05px);
    letter-spacing: 0.3px;
    font-family: $inter;
    color: $dark-gray;
    margin-bottom: 0;
    padding-bottom: 24px;
  }

  .menu {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .content {
      font-family: $inter;
      font-size: 13px;
      padding: 15px 20px;
      border-bottom: 1px solid $gray;
      display: flex;
      justify-content: flex-start;
      gap: 40px;

      & span:nth-child(1) {
        font-weight: 700;
        margin-right: 5px;
      }

      &:last-child {
        border: none;
      }

      .platform {
        width: 61px;
        height: 61px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 14px;
        color: $white;
      }

      .right {
        div {
          color: $grayColor;
          font-weight: 400;
          font-size: 13px;
          max-width: 650px;
        }

        .position {
          font-weight: 600;
          font-size: 18px;
          color: $colorText;
        }
        .time {
          font-weight: 400;
          font-size: 14px;
        }
        .business {
          font-weight: 500;
          font-size: 14px;
          color: $colorText;
          position: relative;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          img {
            transform: translate(50%, -15%);
            cursor: pointer;
          }
        }

        .grade {
          font-weight: 400;
          font-size: 14px;

          span {
            font-weight: 700;
            font-size: 14px;
            color: $colorText;
          }
        }
        .edit {
          img {
            user-select: none;
          }
          &::after {
            position: absolute;
            content: '';
            width: 18px;
            height: 1px;
            background-color: #344563;
            bottom: 20%;
            transform: translate(-18%);
          }
        }

        .modal_container {
          font-family: $inter;
          position: absolute;
          background-color: $white;
          border-radius: 8px;
          box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.2);
          z-index: 1;
          transform: translateY(4%);
          padding: 20px;

          &::before {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid $white;
            left: 9%;
            top: -4%;
          }

          .label {
            color: #3f3c4c;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            margin-bottom: 4px;
          }

          .business_select {
            width: 426px;
          }

          .function_select {
            min-width: 538px;

            :global {
              .rc-virtual-list-holder-inner {
                margin-right: 4px;
                .ant-select-item-option:first-child {
                  border-bottom: 1px solid #d6d6d6;
                  background: #f2f4f8;
                }
                .ant-select-item-option {
                  border-bottom: 1px solid #d6d6d6;
                }
              }

              .ant-select-dropdown {
                width: 948px !important;
              }
            }
          }
        }

        .function_header {
          display: grid;
          grid-template-columns: repeat(3, 33%);

          div {
            color: #181818;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            letter-spacing: 0.235px;
          }
        }

        .function_rows {
          display: grid;
          grid-template-columns: repeat(3, 33%);

          div {
            text-wrap: wrap;
            color: var(--base-black, #181818);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.235px;
          }
        }

        :global {
          .ant-select-item-option {
            border: none;
            .ant-select-item-option-content {
              color: #181818;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.235px;
            }
          }
          .rc-virtual-list {
            padding: 10px;
          }
          .ant-select-selector {
            padding: 0px 16px;
          }
          .ant-select-selection-search-input {
            font-size: 14px;
            color: #181818;
            padding-left: 5px;
          }
          .ant-select-selection-placeholder {
            font-size: 14px;
            color: #8d8d8d;
          }
        }
      }
    }
  }
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.saveButton,
.cancelButton {
  padding: 8px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  border: none;
}

.saveButton {
  background-color: $grayColor;
  color: white;
}

.cancelButton {
  background-color: $white;
  color: $colorText;
  border: 1px solid $colorText;
  margin-right: 15px;
}

@media print {
  body {
    display: block !important;
    position: relative !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    margin-left: 0 !important;
  }
}

@page {
  size: 'A4';
  margin-top: 20px;
}
