@import '../../../../assets/scss/variables';
$arrowWidth: 63px;
$connectThickness: 2px;
$connectRadius: 8px;
$rowCardSpace: 20px;
$connectColor: #aabcd0;
$borderConnect: $connectThickness solid $connectColor;

.mobilitySection,
.recommendSection {
  padding: 30px;
  margin-top: 30px;
  background-color: $white;
  border-radius: 6px;
  font-family: $inter;

  .title {
    font-weight: 600;
    font-size: 18px;
    color: #3f3c4c;
  }
}

.mobilitySection {
  .selectMobilityOption {
    display: flex;
    align-items: center;
    height: 32px;
    gap: 6px;
  }

  .topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .left {
      display: flex;
      gap: 20px;
      align-items: center;

      .setAsMain {
        display: flex;
        align-items: center;
        gap: 6px;
        font-weight: 500;
        font-size: 14px;
        color: #181818;
      }

      .moreEditBtn {
        padding: 2px 10px;
        margin-left: -10px;
        cursor: pointer;
      }

      .moreOption {
        font-size: 14px;
        font-weight: 400;
        color: #181818;
        display: flex;
        gap: 6px;
        align-items: center;
        padding: 2px;

        img {
          width: 14px;
          height: 14px;
        }
      }
    }

    .right {
      display: flex;
      gap: 16px;
      width: 563px;
      justify-content: flex-end;
      align-items: center;

      .select {
        width: 300px;
      }
    }
  }

  :global {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 32px;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }
  }
}

.listCardSingle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .arrow {
    width: $arrowWidth;
  }

  .rowOdd,
  .rowEven {
    display: flex;
  }

  .rowOdd {
    flex-direction: row-reverse;
  }

  .rowConnectArrow {
    width: $pdcCardWidth;
    display: flex;
    justify-content: center;

    img {
      height: 51px;
    }
  }
}

.listCardMultiple {
  display: flex;
  flex-direction: column;
  gap: $rowCardSpace;

  .tempCard {
    width: $pdcCardWidth;
  }

  .rows {
    display: flex;
    justify-content: center;
    gap: $arrowWidth;
  }
}

.recommendSection {
  .topBar {
    display: flex;
    justify-content: space-between;
  }

  .type {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    font-size: 10px;
    font-weight: 400;
    color: #444444;

    div {
      display: flex;
      align-items: center;

      &:first-child span {
        background-color: $pdcApprovedSPColor;
      }

      &:last-child span {
        background-color: $pdcAIRecommendColor;
      }
    }

    span {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 4px;
      margin-right: 6px;
    }
  }

  .recommendations {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-wrap: wrap;
    gap: 20px $arrowWidth;

    .draggableCard {
      width: $pdcCardWidth;
      display: flex;
    }
  }
}

.addCard {
  border-radius: 6px;
  border: 1px solid #e7e7f0;
  width: $pdcCardWidth;
  min-height: 160px;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #787587;
  text-align: center;

  img {
    margin-bottom: 12px;
  }
}

.mainTag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 32px;
  font-size: 16px;
  font-weight: 500;
  color: #5b3075;
  background-color: #ebe1f1;
  border-radius: 6px;
}

//Draw Connect Line Multiple Mobility
.wrapCard {
  display: flex;
  position: relative;
}

.connector {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
}

.leftConnect,
.rightConnect {
  position: absolute;
  top: 50%;
  width: calc($arrowWidth / 2 + 2px);
  height: 8px;
  border-bottom: $borderConnect;
}

.leftConnect {
  border-bottom-right-radius: $connectRadius;
  border-right: $borderConnect;
  left: 100%;
}

.rightConnect {
  border-bottom-left-radius: $connectRadius;
  border-left: $borderConnect;
  right: 100%;

  span {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 10px solid $connectColor;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(7px);
  }
}

.lineBetween,
.lineBetweenFirst,
.lineBetweenLast {
  position: absolute;
  box-sizing: border-box;
  width: calc($arrowWidth / 2 + $connectThickness);
  border-width: 2px;
  &.left {
    border-right: $borderConnect;
  }
  &.right {
    border-left: $borderConnect;
  }
}

.lineBetweenFirst {
  top: 50%;
  transform: translateY(calc(-1 * $connectThickness / 2));
  height: calc(50% + $connectThickness);
  border-top: $borderConnect;

  &.left {
    border-top-right-radius: $connectRadius;
    left: calc(100%);
    border-right: $borderConnect;
    width: calc($arrowWidth / 2);
  }
  &.right {
    border-top-left-radius: $connectRadius;
    left: calc(100% + $arrowWidth / 2);
    border-left: $borderConnect;
  }
}

.lineBetween {
  bottom: 0;
  height: calc(100% + $rowCardSpace);

  &.left {
    left: 100%;
  }
  &.right {
    right: 100%;
  }
}

.lineBetweenLast {
  bottom: 50%;
  transform: translateY(calc($connectThickness / 2));
  height: calc(50% + $rowCardSpace + 2px);
  border-bottom: 2px solid #aabcd0;
  &.left {
    border-bottom-right-radius: $connectRadius;
    left: 100%;
  }
  &.right {
    border-bottom-left-radius: $connectRadius;
    right: 100%;

    span {
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 10px solid $connectColor;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateY(7px);
    }
  }
}
