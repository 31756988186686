@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';
.wrapper {
  font-family: $inter;
  padding: 25px 22px 20px 29px;
  background: #f4f5f8;
  width: 100%;
  border-radius: 6px;

  .header {
    @include flex(center, space-between);
    padding-bottom: 9px;
  }

  .label {
    @include showText(24px, 600, none, 29.05px);
    color: $dark-gray;
  }

  .table {
    display: flex;
    flex-direction: column;
    background-color: $white;

    .table_header {
      display: flex;

      .line_title {
        padding: 11px 0 0 15px;
        border-bottom: 1px solid #ececec;
        border-right: 1px solid #ececec;
        width: calc(100% / 3);
        p {
          margin-bottom: 0;
          &:first-child {
            @include showText(13px, 500, none, 15.73px);
            color: $dark-blue;
          }
          &:last-child {
            @include showText(10px, 500, none, 12.1px);
            padding-bottom: 9px;
            color: $dark-blue;
          }
        }
      }
    }

    .role,
    .cell_key {
      padding: 11px 0 0 15px;
      border-bottom: 1px solid #ececec;
      border-right: 1px solid #ececec;
      width: 65px;
    }

    .table_data {
      display: flex;
      flex-direction: column;

      .cell_key {
        color: #3f3c4c;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .row_data {
        display: flex;

        .cell_data {
          padding: 12px 15px;
          border-bottom: 1px solid #ececec;
          border-right: 1px solid #ececec;
          width: calc(100% / 3);
        }
      }
    }
  }

  .item {
    p {
      margin-bottom: 0;
      color: $dark-blue;
      &:first-child {
        @include showText(13px, 600, none, 16px);
      }
      &:last-child {
        padding-top: 5px;
        @include showText(12px, 400, none, 16px);
      }
    }
  }
}
