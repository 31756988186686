@import '../../../../assets/scss/variables';

.wrapper {
  padding: 28px 28px 30px 30px;
  background-color: #f4f5f8;
  border-radius: 6px;
  font-family: $inter;

  .title {
    font-size: 24px;
    font-weight: 600;
    text-transform: none;
    line-height: 29.05px;
    color: #5e6c84;
  }

  .list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 30px;

    .content {
      font-size: 13px;
      padding: 15px 30px;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      display: flex;
      justify-content: flex-start;
      gap: 30px;
      background-color: white;

      & span:nth-child(1) {
        font-weight: 700;
        margin-right: 5px;
      }

      .platform {
        background-color: gray;
        width: 61px;
        height: 61px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 14px;
        color: $white;
      }

      .right {
        p {
          color: #344563;
          font-weight: 400;
          font-size: 13px;
        }
        .date {
          font-weight: 700;
          font-size: 14px;
        }
      }
    }
  }
}
