@import '../../../../assets/scss/variables';

.wrapper {
  padding: 28px 28px 30px 30px;
  background-color: #f4f5f8;
  border-radius: 6px;
  font-family: $inter;

  .title {
    font-size: 24px;
    font-weight: 600;
    text-transform: none;
    line-height: 29.05px;
    color: #5e6c84;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background: white;
    overflow: hidden;
    border-radius: 8px;
    margin-top: 16px;
  }

  th,
  td {
    padding: 16px;
    color: #3f3c4c;
    font-size: 14px;
  }

  th {
    border-bottom: 2px solid #d3dee8;
    font-weight: 500;

    &:nth-child(1) {
      width: 20%;
    }
    &:nth-child(2) {
      width: 20%;
      text-align: center;
    }
    &:nth-child(3) {
      width: 60%;
    }
  }

  td {
    border-bottom: 1px solid #d3dee8;
    &:nth-child(2) {
      text-align: center;
    }
  }

  tbody {
    tr:last-child td {
      border-bottom: none;
    }
  }
}
