@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixin";
.SectionName {
  font-family: $inter;
  @include showText(24px, 600, none, 29.05px);
  color: #5e6c84;
}

.SectionPlan {
  padding: 25px 22px 20px 22px;
  background: #f4f5f8;
  width: 100%;
  border-radius: 6px;
}
.tablePlan {
  background: #ffffff;
  margin-top: 20px;
  border-radius: 6px;
  table {
    margin-bottom: 0;
    tr {
      border-bottom: 1px solid #ececec;
    }
  }
  &Thead th {
    font-family: $inter;
    @include showText(14px, 500, none, 16.94px);
    color: #344563;

    &:first-child{
      width: 25%;
    }
  }
  &Body {
    font-family: $inter;
    @include showText(14px, 400, none, 16.94px);
    color: #344563;
  }
  &BodyStatus {
    text-transform: capitalize;
  }
}
.btn {
  width: 100%;
  height: 72px;
  background: #f4f5f8;
  border-radius: 6px;
  border: 1px solid #e9edf0;
  box-sizing: border-box;
}
.TextBtn {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #344563;
}
.btn:hover {
  background: #e9edf0;
}
