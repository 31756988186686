@import '../../../../assets/scss/mixin';
@import '../../../../assets/scss/variables';

.userInfo {
  @include flex(flex-start, space-between);
  width: 100%;
  gap: 30px;

  .leftInfo {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 30px;

    .basicInfo {
      display: flex;
      gap: 30px;
    }
  }

  .rightInfo {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 30px;
  }
}

.aboutMeModal {
  padding-top: 50px;
  .header {
    display: flex;
    gap: 28px;

    .avatar {
      border: 3px solid #14919b;
      border-radius: 50%;
      filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12));
      width: 155px;
      height: 155px;
      margin-top: 10px;
    }
    .userNameAndPosition {
      max-width: max-content;
    }
  }

  .detail {
    margin-top: 34px;

    .title {
      color: #3f3c4c;
      letter-spacing: 0.83px;
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 20px;
    }

    .content {
      color: #3f3c4c;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      white-space: pre-line;
      max-height: 552px;
      overflow-y: scroll;
      padding-right: 45px;
    }
  }
}

.userNameAndPosition {
  max-width: 308px;
  p {
    color: $textColor;
    font-family: $inter;
  }
  p:first-child {
    @include showText(14px, 400, capitalize, 16.94px);
    margin-bottom: 19px;
  }
  p:nth-child(2) {
    @include showText(24px, 700, capitalize, 29px);
    margin-bottom: 9px;
  }
  p:nth-child(3) {
    @include showText(18px, 700, capitalize, 22px);
    margin-bottom: 9px;
  }
  p:nth-child(4) {
    @include showText(18px, 700, capitalize, 22px);
    margin-bottom: 9px;
  }
  p:nth-child(5) {
    @include showText(18px, 700, capitalize, 22px);
    margin-bottom: 9px;
  }
  p:last-child {
    @include showText(14px, 500, none, 17px);
  }
}

.userCareer {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  tr th {
    background-color: #00a19c;
    color: white;
    @include showText(14px, 500, none);
  }
  tr td {
    background-color: #f4f5f8;
    @include showText(14px, 500, none);
  }

  tr {
    td,
    th {
      font-family: $inter;
      width: calc(100% / 6);
      border-bottom: 1px solid #cccccc;
    }
    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid #cccccc;
    }
    th:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    th:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    th:first-child,
    td:first-child {
      padding: 15.02px 11.84px 16.27px;
    }
    th:not(:first-child),
    td:not(:first-child) {
      text-align: center;
    }
    &:nth-child(2) {
      td:first-child {
        border-top-left-radius: 6px;
      }
      td:last-child {
        border-top-right-radius: 6px;
      }
    }
    &:last-child {
      td:first-child {
        border-bottom-left-radius: 6px;
      }
      td:last-child {
        border-bottom-right-radius: 6px;
      }
    }
  }
  .designation {
    @include showText(13px, 500, none);
  }
  .desginationText {
    color: $textColor;
    @include showText(12px, 500, none);
    line-height: 14.52px;
    margin-bottom: 0;
  }
}

.userInfoDetail {
  font-family: $inter;
  .listInfo {
    @include flex(flex-start, space-between, row, wrap);
    padding-bottom: 21px;
    .itemInfo {
      text-align: center;
      padding: 15px 0 11px 0;
      line-height: 24px;
      font-size: 14px;
      p:first-child {
        color: $textColor;
        font-weight: 400;
      }
      p:last-child {
        color: $textColor;
        font-weight: 600;
      }
    }
  }
  .aboutMe {
    color: $textColor;
    max-width: 647px;
    @include flex(flex-start, flex-start, column, nowrap);
    p {
      font-weight: 600;
      line-height: 24px;
      font-size: 14px;
      margin-bottom: 6px;
      color: $textColor;
    }
    span {
      @include showText(12px, 500, none);
      line-height: 21px;
      color: $textColor;
    }

    .viewMore {
      font-weight: 700;
      color: $teal;
      cursor: pointer;
    }
  }
  .switch {
    @include flex(center, flex-end);
  }
}

.userCareerList {
  width: 100%;
  display: grid;
  grid-template-columns: 20% 20% 20% 20%;
  grid-gap: 5%;
  text-align: center;
  align-items: flex-start;

  .userCareerItem {
    min-width: 135px;
    align-items: center;
    font-family: $inter;

    p:first-child {
      @include showText(14px, 400, none);
      margin-bottom: 9px;
      color: $textColor;
    }
    p:nth-child(2) {
      @include showText(14px, 600, none);
      color: $textColor;
      margin-bottom: 5px;
    }
    p:last-child {
      @include showText(14px, 600, none);
      color: $textColor;
    }
  }
}

.skeleton {
  &__marginBtn {
    margin-bottom: 10px;
  }
}

.jg {
  &:hover {
    cursor: pointer;
  }
}

:global(.jobGradeTooltip) {
  :global(.tooltip-inner) {
    @include showText(12px, 600, none, 14.52px);
    font-family: $inter;
    padding: 15px 27px;
    border-radius: 6px;
  }
}

.avatar_wrapper {
  position: relative;
  height: fit-content;
}

.tooltipContent {
  padding: 5px;

  span {
    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.0168em;
    color: #ffffff;
  }
}
