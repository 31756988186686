@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

.containerModal {
  max-width: 1287px;
  width: 1287px;

  :global {
    .modal-body {
      padding: 0;
    }
  }
}

.printContainer {
  padding: 25px 15px;
}

.settingIcon {
  position: absolute;
  right: 15px;
  top: 25px;
}

.card {
  margin-bottom: 1.5rem;
  border-radius: 6px;
  background: white;
}

.distance {
  margin-top: 16px;
  break-before: auto;
  break-after: auto;
  break-inside: auto;
}

.btn_wrapper {
  @include flex();
  margin-left: 22px;
}

@media print {
  body {
    display: block !important;
    position: relative !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    margin-left: 0 !important;
  }

  .container {
    .printTable {
      tbody {
        display: table-row-group !important;
        max-height: fit-content !important;
      }
    }
  }
}

@page {
  size: 'A4';
}
