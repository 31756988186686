@import '../../assets/scss/variables';

.header {
  width: 1600px;
  height: 70px;
  background-repeat: no-repeat;
  background-image: url('../../assets/img/background_header.png');
  object-fit: cover;

  .container {
    margin: 0 30px;
    display: flex;
    justify-content: space-between;

    .left {
      position: relative;
      display: flex;
      gap: 10px;
      align-items: center;

      .logo {
        background-image: url('../../assets/img/header_logo.svg');
        background-position: center;
        width: 220px;
        height: 70px;
        cursor: pointer;
        position: relative;

        .text {
          width: 75px;
          height: 19px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-54%, -52%);
          user-select: none;
        }
      }

      .toggle {
        right: 0%;
        display: flex;
        align-items: center;
        cursor: pointer;

        .menuOutlined {
          font-size: 20px;
          position: relative;
          color: #fff;
          
          &:after {
            content: '';
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #f8f8f8;
            position: absolute;
            opacity: 0;
            transition: linear 0.8s;
            z-index: -1;
            top: 0;
            left: 0;
            transform: translate(-25%, -25%);
          }

          &:active:after {
            opacity: 0.6;
            transition: 0s;
          }
        }
      }
    }

    .right {
      margin-left: 50px;
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header_group {
        display: flex;
        align-items: center;
        gap: 10px;

        .user_name {
          color: $white;
          font-family: $inter;
          font-weight: 500;
        }
      }
    }
  }
}

.dropdown {
  button {
    padding: 0;
    width: 50px;
    background-color: $teal !important;
    border: none !important;
    box-shadow: none !important;
  }

  a {
    text-align: center;
  }
}
