@import "../../../../assets/scss/variables";

.SectionName {
  font-family: $inter;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: #5e6c84;
}

.SectionPlan {
  padding: 25px 22px 20px 22px;
  background: #f4f5f8;
  width: 100%;
  border-radius: 6px;
}
.tablePlan {
  background: #ffffff;
  margin-top: 20px;
  border-radius: 6px;
  table {
    margin-bottom: 0;
    font-family: $inter;
    tr {
      border-bottom: 1px solid #ececec;
    }
  }
  &Thead th {
    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #344563;
  }
  &Body td {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #344563;
  }
}
.btn {
  width: 100%;
  height: 72px;
  background: #f4f5f8;
  border-radius: 6px;
  border: 1px solid #e9edf0;
  box-sizing: border-box;
}
.TextBtn {
  font-family: $inter;
  font-weight: 700;
  font-size: 18px;
  color: #344563;
}
.btn:hover {
  background: #e9edf0;
}
