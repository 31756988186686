// Using material design with react dropdown tree select
// https://codesandbox.io/s/using-material-design-with-react-dropdown-tree-select-forked-n42l42?file=/src/index.css

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}
.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
.mdl-demo {
  .dropdown-trigger {
    & > span {
      &:after {
        font-size: 12px;
        color: #8d8d8d;
      }
    }
    &.arrow {
      &.bottom {
        &:after {
          content: '\25BC';
          vertical-align: middle;
          color: #d9d9d9;
          line-height: 0;
          margin-right: 2px;
          font-size: 10px;
        }
      }
      &.top {
        &:after {
          content: '\25B2';
          vertical-align: middle;
          color: #d9d9d9;
          line-height: 0;
          margin-right: 2px;
          font-size: 10px;
        }
      }
    }
  }
  .tag-list {
    .tag-item {
      display: none;
    }
  }
  .tag-item {
    &.special {
      display: none;
    }
  }
  .node-label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    color: #3f3c4c;
    text-transform: initial;
  }
  .toggle {
    font: normal normal normal 18px/1 'Material Icons', sans-serif;
    color: #555;
    white-space: pre;
    margin-right: 4px;
    &.collapsed {
      &::after {
        cursor: pointer;
        content: '\E5CF';
        vertical-align: middle;
      }
    }
    &.expanded {
      &::after {
        cursor: pointer;
        content: '\E5CE';
        vertical-align: middle;
      }
    }
  }
  .dropdown-content {
    max-height: 430px;
    overflow-y: auto;
    position: absolute;
    z-index: 100;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 8px 16px 0px #0000001a;
    min-width: 360px;
    padding-top: 10px;

    .search {
      position: relative;
      display: flex;
      align-items: center;
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #8d8d8d;
      padding: 9px 14px 9px 10px;
      background: #ffffff;
      border: 1px solid #d3dee8;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      width: 100%;
    }

    .no-matches {
      font-size: 14px;
      color: #3f3c4c;
      padding: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #ffffff;
      border-radius: 4px;
    }
    &::-webkit-scrollbar {
      width: 2px !important;
      background-color: #ffffff;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(74, 74, 74, 0.5);
      border-radius: 4px;
      border: 2px solid rgba(74, 74, 74, 0.5) !important;
    }
    ul {
      margin: 0;
      padding: 0;
      max-height: 468px;
    }
  }
  &.fit-content {
    .dropdown-content {
      ul {
        min-width: 207px;
      }
    }
  }
  .checkbox-item {
    position: relative;
    width: 16px;
    height: 16px;
    margin-right: 0.75rem;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    vertical-align: middle;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border: 2px solid #aaa;
      border-radius: 4px;
      background-color: transparent;
      transition: all 0.3s ease-in-out;
    }
    &:checked {
      &:before {
        border-radius: 4px;
        border-color: #00a19c;
        background-color: #00a19c;
      }
      &:after {
        content: '';
        position: absolute;
        left: 4px;
        top: 4px;
        z-index: 1;
        width: 8px;
        height: 5px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        border: 1px solid #aaa;
        border-top-style: none;
        border-right-style: none;
        border-color: #ffffff;
      }
    }
  }
  .checkbox-item[disabled] {
    & ~ .node-label {
      opacity: 0.5;
    }
  }
}
.filter_section {
  margin: 20px 0;
}
.filter_wrapper {
  position: relative;
  margin-bottom: 10px;
  & > .mdl-demo {
    &.min-width-186 {
      .dropdown-content {
        ul {
          min-width: 328px;
        }
      }
    }
  }
  .btn-apply {
    position: absolute;
    z-index: 1000;
    background-color: white;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }
}
.filter_wrapper > .mdl-demo .dropdown-trigger.arrow.top,
.filter_wrapper > .mdl-demo .dropdown-trigger.arrow.bottom {
  position: absolute;
  top: -40px;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 11px 5px;
  min-width: 100%;
  text-decoration: none;
}
.filter_button {
  position: relative;
  display: flex;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8d8d8d;
  padding: 9px 14px 9px 10px;
  background: #ffffff;
  border: 1px solid #d3dee8;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: 100%;
  img {
    margin-right: 6px;
  }
}
.filter_button.active,
.filter_button.active ~ .mdl-demo .dropdown-trigger.arrow.bottom:after,
.filter_button.active ~ .mdl-demo .dropdown-trigger.arrow.top:after {
  color: #000000;
}
.filter_apply_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  background: #00a19c;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  margin: 20px 15px 15px auto;
}
.clear_filter_button {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #00847c;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  margin: 10px 0 0;
}
.infinite-scroll-component {
  padding-bottom: 52px;
}
.filter_wrapper > .mdl-demo.min-width-290 .dropdown-trigger.arrow.top,
.filter_wrapper > .mdl-demo.min-width-290 .dropdown-trigger.arrow.bottom {
  min-width: 262px;
}
.filter_wrapper > .mdl-demo.min-width-315 .dropdown-trigger.arrow.top,
.filter_wrapper > .mdl-demo.min-width-315 .dropdown-trigger.arrow.bottom {
  min-width: 290px;
}
.filter_wrapper > .mdl-demo.min-width-186 .dropdown-trigger.arrow.top,
.filter_wrapper > .mdl-demo.min-width-186 .dropdown-trigger.arrow.bottom {
  min-width: 168px;
}
.filter_wrapper > .mdl-demo.min-width-338 .dropdown-trigger.arrow.top,
.filter_wrapper > .mdl-demo.min-width-338 .dropdown-trigger.arrow.bottom {
  min-width: 318px;
}

.position_filter_wrapper {
  .mdl-demo .dropdown-content ul {
    padding-top: 45px;
  }

  .mdl-demo .dropdown .dropdown-content {
    position: initial !important;
  }

  .mdl-demo .dropdown .dropdown-content .search {
    border: solid 1px #5d7083;
    border-radius: 4px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 15;
    font-family: $inter;
    font-size: 14px;
    line-height: 20px;
    color: #15191e;
    padding: 9px 14px 9px 10px;
    background: #fff;
    box-shadow: none;
    outline: none;

    &::placeholder {
      color: #5d7083;
    }
  }

  .node.leaf {
    label {
      max-width: 370px;
    }
  }
}
