@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

.careerStatement {
  padding: 24px 30px 14px 30px;
}

.aspiration {
  padding: 24px 30px;
  padding-top: 0;
}

.container {
  border-radius: 6px;
  font-family: $inter;

  h3 {
    @include showText(24px, 600, none, 29.05px);
    color: #787587;
    padding-bottom: 18px;
  }

  .item {
    @include showText(14px, 500, none, 16.94px);
    letter-spacing: 0.3px;
    position: relative;
    text-align: justify;
    white-space: pre-wrap;
    ol {
      list-style-type: none;
      font-weight: 400;
    }
  }

  .title {
    color: #3d3d3d;
    font-weight: 700;
  }
  .content {
    color: #181818;
    font-weight: 500;
    font-size: 14px;
  }

  .divider {
    border-color: #cdcdcd;
    margin-top: 0;
  }

  .aspirationMatching {
    h3 {
      color: #787587;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      margin-top: 24px;

      .item {
        border-radius: 16px;
        background: rgba(0, 161, 156, 0.05);
        padding: 7px 15px;
        color: #00a19c;
        text-align: justify;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.3px;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #3f3c4c;

    .disclaimer {
      font-size: 12px;
      font-style: italic;
      font-weight: 600;
      margin-top: 24px;
    }

    .openModal {
      display: flex;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.235px;
      margin-top: 36px;
      gap: 12px;
      align-items: center;
      cursor: pointer;

      .view {
        display: flex;
        gap: 10px;
        align-items: center;
        cursor: pointer;

        &:hover {
          border-bottom: 1px solid #3f3c4c;
        }
      }

      .edit:hover {
        opacity: 0.5;
      }
    }
  }
}

//CareerAspirationModalTable style
.jobFuncDesc {
  display: flex;
  gap: 18px;
  background-color: #f5f5f5;
  color: #3d3d3d;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.173px;
  width: fit-content;
  height: 40px;
  align-items: center;
  padding: 0 20px;
  margin-top: 22px;
  margin-bottom: 34px;
}

.table {
  font-family: $inter;

  .text {
    color: #181818;
  }

  .textInfo {
    color: #3f3c4c;
  }

  .textListPosition {
    color: #00908c;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.235px;
    background: transparent;
    border: none;
    white-space: nowrap;

    &.disable {
      pointer-events: none;
      opacity: 0.75;
    }
  }

  .positionTable {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .close {
      margin-bottom: -8px;
      cursor: pointer;

      &:hover {
        opacity: 0.75;
      }
    }
  }
}

.groupCol {
  display: flex;
  justify-content: space-between;
}

.fieldWrapper {
  display: flex;
  align-items: center;
}

.controlBar {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
}

.modalOutput,
.admin {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 9px;
}

.modalOutput {
  background-color: $teal;
}
.admin {
  background-color: #005cb9;
}

.indicators {
  font-family: $inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  color: #3f3c4c;
}
.viewMore {
  font-family: $inter;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: #3f3c4c;
  user-select: none;
  cursor: pointer;
}
