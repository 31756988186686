@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

.container {
  background-color: #f4f5f8;
  font-family: $inter;
  display: flex;
  flex-direction: column;
  border-radius: 6px;

  .otherContribution {
    padding: 28px 26px 0 24px;

    &__h3 {
      @include showText(24px, 600, none, 29.05px);
      letter-spacing: 0.3px;
      color: $dark-gray;
      margin-bottom: 0;
      padding-bottom: 24px;
    }
    &__table {
      background: #ffffff;
      box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.04);
      border-radius: 6px;
      margin-bottom: 28px;
  
      table {
        width: 100%;
      }
  
      p {
        margin-bottom: 0;
      }
      th {
        p {
          color: $dark-blue;
          @include showText(14px, 500, none, 16.94px);
        }
        padding-top: 17px;
        padding-bottom: 13px;
        &:first-child {
          padding-left: 26px;
          width: 300px;
        }
        &:last-child {
          width: 156px;
        }
        width: 155px;
        &:not(:first-child) {
          text-align: center;
        }
        box-shadow: inset 0px -2px 0px #ececec;
      }
      td {
        p {
          color: $dark-blue;
          @include showText(14px, 400, none, 16.94px);
        }
        padding-bottom: 16px;
        padding-top: 9px;
        &:first-child {
          padding-left: 26px;
        }
        &:not(:first-child) {
          text-align: center;
        }
        &:not(:first-child):not(:last-child) {
          p {
            padding-left: 10px;
          }
        }
      }
      tr {
        &:not(:last-child) {
          td {
            box-shadow: inset 0px -1px 0px #ececec;
          }
        }
      }
    }
  }

  .projectInvolvement {
    padding: 0 26px 27px 24px;
  }

  .btn {
    width: 100%;
    height: 54px;
    background: $dark-blue;
    border-radius: 6px;
    border: 1px solid #e9edf0;
    box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  }
  .TextBtn {
    font-family: $inter;
    font-weight: 700;
    font-size: 14px;
    color: white;
  }
  .btn:hover {
    background: #e9edf0;
  }
}
