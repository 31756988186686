@import '../../../../assets/scss/variables';

.suffixIcon {
  border: 1px solid #bac7d5;
  border-radius: 6px;
  background-color: white;
  padding: 8px 8px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
}
