@import '../../../assets/scss/variables';
$wrapper-width: 1120px;

.wrapper {
  background-color: #f4f5f8;
  font-family: $inter;
}

.printSection {
  font-family: $inter;
  width: $wrapper-width;
  margin: auto;
  min-height: 100vh;
  background-color: #f4f5f8;

  section {
    margin-bottom: 20px;
    background-color: $white;
    border-radius: 6px;
  }
}

.btnRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: $wrapper-width;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #3d3d3d;

  & > div {
    display: flex;
    gap: 10px;
  }
}

.loadingBar {
  width: $wrapper-width;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #3d3d3d;

  .loadingProcess {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 14px;
  }

  progress {
    flex-grow: 1;
    height: 10px;
    appearance: none;
  }

  progress::-webkit-progress-bar {
    background-color: #e0f3f3;
    border-radius: 10px;
    border: 1px solid #00807c;
  }

  progress::-webkit-progress-value {
    background-color: #00807c;
    border-radius: 10px;
  }
}

.meetingDetailSection {
  background-color: white;
  padding: 16px;
  border-radius: 6px;
  margin-bottom: 32px;
}

.mobilityTitle {
  color: #3f3c4c;
  font-weight: 700;
  font-size: 24px;
  padding: $pdcPrintPadding;
  word-break: break-word;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #cbd6e2;
}

.floatBtn{
  position: fixed;
  right: calc(50% - 700px);
  bottom: 50px;
}